import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import bg2 from 'assets/img/generic/Footer3.png';
import Section from 'components/common/Section';

const Cta = () => (
  <Section
    overlay
    image={bg2}
    position="center top"
    className="bg-dark"
    data-bs-theme="light">
    <Row className="justify-content-center text-center">
      <Col lg={8}>
        <p className="fs-3 fs-sm-4 text-white fw-bold">
          Amanita is the AI-driven revolution that is enabling a generation of
          entrepreneurs and Go-To-Market leaders to build and scale high-growth
          businesses.
        </p>
        <Button
          variant="light"
          size="lg"
          className="border-2 rounded-pill mt-4 fs-0 py-2">
          Join Us
        </Button>
      </Col>
    </Row>
  </Section>
);

export default Cta;
