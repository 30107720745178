import React, { useEffect, useMemo, useRef } from 'react';
import '../Pages/market-research/style.css';
import { Controller, useForm } from 'react-hook-form';
import { colorsObjDark } from 'helpers/utils';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import MultiSelect from './common/MultiSelect';

const Filter = ({
  filter,
  setFilter,
  upload,
  allMarketResearch,
  setAllFilters
}) => {
  const modalRef = useRef(null);
  const isDark = false;

  const {
    register,
    handleSubmit,
    setValue,
    // formState: { errors },
    control
  } = useForm({
    defaultValues: {
      publishers: [],
      publication: '',
      contentType: ''
    }
  });

  const onSubmit = data => {
    const nonEmptyFilters = Object.entries(data).reduce((acc, [key, value]) => {
      // Check for non-empty strings and arrays
      if (
        typeof value === 'string'
          ? value.trim() !== ''
          : Array.isArray(value)
          ? value.length > 0
          : value
      ) {
        acc[key] = value;
      }
      return acc;
    }, {});
    console.log(nonEmptyFilters);
    setAllFilters(nonEmptyFilters); // setFilter(false);
  };

  const handleClick = () => {
    setFilter(!filter);
  };

  useEffect(() => {
    const handleOutsideClick = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setFilter(false);
      }
    };

    if (filter) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [filter]);

  // const options = [
  //   { label: 'Science Fiction', value: 'sci-fi' },
  //   { label: 'Fantasy', value: 'fantasy' },
  //   { label: 'Mystery', value: 'mystery' },
  //   { label: 'Thriller', value: 'thriller' },
  //   { label: 'Romance', value: 'romance' },
  //   { label: 'Young Adult', value: 'young-adult' },
  //   { label: 'Non-Fiction', value: 'non-fiction' }
  // ];

  const publishersOption = useMemo(() => {
    const publishers = allMarketResearch.map(
      marketResearch => marketResearch.publisher
    );
    const uniquePublishers = [...new Set(publishers)];
    return uniquePublishers.map(publisher => ({
      label: publisher,
      value: publisher
    }));
  }, [allMarketResearch]);

  return (
    <div className="filter">
      <button
        onClick={handleClick}
        style={
          upload
            ? {
                backgroundColor: isDark ? colorsObjDark.pink : '#F6EFDB',
                color: isDark ? 'white' : 'black'
              }
            : { backgroundColor: colorsObjDark.sidebg }
        }
        className="main-btn">
        Filter
      </button>
      {filter && (
        <Form onSubmit={handleSubmit(onSubmit)} ref={modalRef}>
          <div
            className={`filter-container ${isDark ? 'dark' : 'light'}`}
            style={{ maxWidth: '345px' }}>
            <h4 className="">Filter by</h4>
            <Form.Group
              className="filter-content mb-3"
              controlId="exampleForm.ControlInput1">
              <Form.Label>Select Publishers</Form.Label>
              <Controller
                name="publishers"
                render={({ field, ref }) => (
                  <MultiSelect
                    ref={ref}
                    closeMenuOnSelect={false}
                    isMulti
                    options={publishersOption}
                    {...field}
                    onChange={selected => {
                      setValue('publishers', selected);
                    }}
                    // style={{
                    //   border: '2px solid' + colorsObjDark.pink
                    // }}
                  />
                )}
                control={control}
              />
            </Form.Group>
            <Form.Group
              className="filter-content mb-3"
              controlId="exampleForm.ControlInput1">
              <Form.Label>Publication Date</Form.Label>
              <Form.Control
                type="date"
                // style={{
                //   border: '2px solid' + colorsObjDark.pink
                // }}
                {...register('publication', { required: false })}
              />
            </Form.Group>
            <Form.Group
              className="filter-content mb-3"
              controlId="exampleForm.ControlInput1">
              <Form.Label>Content Type</Form.Label>
              <Form.Control
                type="text"
                // style={{
                //   border: '2px solid' + colorsObjDark.pink
                //   }}
                {...register('contentType', { required: false })}
              />
            </Form.Group>

            <button
              type="submit"
              className="form-btn"
              style={{
                backgroundColor: isDark ? colorsObjDark.pink : '#762615'
              }}>
              Apply
            </button>
          </div>
        </Form>
      )}
    </div>
  );
};
Filter.propTypes = {
  filter: PropTypes.bool,
  setFilter: PropTypes.func,
  setAllFilters: PropTypes.func,
  upload: PropTypes.bool,
  allMarketResearch: PropTypes.array
};

export default Filter;
