import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const ProductBar = ({ barTitle }) => {
  const navigate = useNavigate();

  return (
    <Row>
      <Col xs={12}>
        <div className="bg-white d-flex justify-content-between align-items-center px-3 py-3 rounded-1">
          <div>
            <h4 className="mb-0">{barTitle}</h4>
          </div>
          <div className="d-flex gap-2 ">
            <Button
              variant=""
              className="shadow-none"
              type="button"
              onClick={() => navigate('/dashboard/product-gallery')}>
              Discard
            </Button>
            <Button variant="primary" type="submit">
              Add Product
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

ProductBar.propTypes = {
  barTitle: PropTypes.string.isRequired // Validate the barTitle prop as a required string
};

export default ProductBar;
