import illustration1 from 'assets/img/icons/spot-illustrations/50.png';
import illustration2 from 'assets/img/icons/spot-illustrations/49.png';
import illustration3 from 'assets/img/icons/spot-illustrations/48.png';

export default [
  {
    icon: ['far', 'lightbulb'],
    iconText: 'PLAN',
    color: 'danger',
    title: 'Research & Design',
    description:
      'With Amanita as your guide, you will know your what your buyers care about most, and how you stack up against your competitors.',
    image: illustration1
  },
  {
    icon: ['far', 'object-ungroup'],
    iconText: 'BUILD',
    color: 'info',
    title: 'Your Growth Engine',
    description:
      'Leverage an analytics platform built for Go-to-Market leaders to understand why you win, why you lose, and how to perpetually improve the buying experience you deliver.',
    image: illustration2,
    inverse: true
  },
  {
    icon: ['far', 'paper-plane'],
    iconText: 'GROW',
    color: 'success',
    title: 'Personalization at Scale',
    description:
      'Build AI-enabled marketing campaigns and buyers journeys that deliver the right message, to the right buyer, at the right time.',
    image: illustration3
  }
];
