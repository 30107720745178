{
  /* CHANGE LOG: 
1. Commented out Partners row. Can add back when we have partners 
2. Commented out Testimonials row. Can add back when we have testimonials
3. Commented out Left Side Navbar. Can add back when we do marketing pages 
4. Commented out Footer. Can add back when we do site maps*/
}

import React from 'react';
import Hero from './Hero';
import NavbarStandard from './NavbarStandard';
/* import Partners from './Partners'; */
import Processes from './Processes';
/*import Services from './Services'; */
/*import Testimonial from './Testimonial';*/
import Cta from './Cta';
/*import FooterStandard from './FooterStandard';*/

const Landing = () => {
  return (
    <>
      <NavbarStandard />
      <Hero />

      {/* <Partners /> */}

      <Processes />

      {/*  <Services /> */}

      {/*  <Testimonial /> */}

      <Cta />
      {/* <FooterStandard /> */}
    </>
  );
};

export default Landing;
