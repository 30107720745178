import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import AmanitaNavTop from 'components/navbar/top/AmanitaNavTop';
import { colorsObj, colorsObjDark } from 'helpers/utils';
import AmanitaSideNav from 'components/navbar/AmanitaSideNav';
import { Stack } from 'react-bootstrap';

const AmanitaLayout = () => {
  const { hash, pathname } = useLocation();
  const isDark = false;

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      {/* {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical />
      )} */}
      <div
        className={classNames('content pb-0')}
        style={{
          backgroundColor: isDark ? colorsObjDark.gray : colorsObj.halfWhite
        }}>
        <AmanitaNavTop />
        <Stack className="w-100" direction="horizontal">
          <AmanitaSideNav />
          {/*------ Main Routes ------*/}
          <div className="outlet" style={{ width: '100%' }}>
            <Outlet />
          </div>
        </Stack>
        {/* {!isKanban && <Footer />} */}
      </div>
    </div>
  );
};

export default AmanitaLayout;
