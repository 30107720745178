import React, { useEffect, useMemo, useState } from 'react';
import Filter from '../../components/filter';
import Upload from '../../components/upload';
import { HEADERS } from 'helpers/utils';
import { Col, Container, Row } from 'react-bootstrap';
import './style.css';
import MarketResearchCard from './components/MarketResearchCard';

const MarketResearch = () => {
  const [filter, setFilter] = useState(false);
  const [upload, setUpload] = useState(false);
  const [editMarketResearchData, setEditMarketResearchData] = useState(null);
  const [isDark, setIsDark] = useState(false);
  const [allMarketResearch, setAllMarketResearch] = useState([]);
  const [allFilters, setAllFilters] = useState({});

  const fetchMArketResearch = async () => {
    try {
      const res = await fetch(
        process.env.REACT_APP_AZURE_FUNCTIONS_BASE_URL + '/api/marketResearch',
        {
          method: 'POST',
          body: JSON.stringify({ apiType: 'getAllMarketResearch' }),
          headers: HEADERS
        }
      );
      const data = await res.json();
      setAllMarketResearch(data?.data || []);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      setIsDark(window.localStorage.getItem('isDark'));
      fetchMArketResearch();
    })();
  }, []);

  const filteredMarketResearch = useMemo(() => {
    if (Object.keys(allFilters).length === 0) {
      return allMarketResearch;
    } else {
      return allMarketResearch.filter(marketResearch => {
        const publisherMatch = allFilters?.publishers?.some(
          publisherFilter => publisherFilter.value === marketResearch.publisher
        );

        const contentTypeMatch = marketResearch?.contentType
          ?.toLowerCase()
          .includes(allFilters?.contentType?.toLowerCase());

        // Assuming allFilters.publication and marketResearch.publication are in YYYY-MM-DD format.
        // const publicationDateMatch = new Date(marketResearch.publication) >= new Date(allFilters.publication);
        const publicationDateMatch =
          marketResearch?.publication === allFilters?.publication;

        return publisherMatch || contentTypeMatch || publicationDateMatch;
      });
    }
  }, [allMarketResearch, allFilters]);

  console.log({ filteredMarketResearch });

  return (
    <div className="main">
      <div className="nav-bar">
        <div className="left">
          <input
            type="text"
            placeholder="Search Library..."
            className={isDark ? 'input-dark' : 'input-light'}
            style={{ color: isDark ? 'white' : 'gray' }}
          />
        </div>
        <div className="right">
          <Filter
            filter={filter}
            setFilter={setFilter}
            upload={upload}
            isDark={isDark}
            allMarketResearch={allMarketResearch}
            setAllFilters={setAllFilters}
          />
          <Upload
            upload={upload}
            setUpload={setUpload}
            filter={filter}
            isDark={isDark}
            onClose={() => {
              setUpload(false);
              setEditMarketResearchData(null);
            }}
            editMarketResearchData={editMarketResearchData}
            fetchMArketResearch={fetchMArketResearch}
          />
        </div>
      </div>
      <Container className="mt-4">
        <h3 className="text-left mb-4">Market Researches</h3>
        <Row className="gy-3 gx-3">
          {filteredMarketResearch.map(marketResearch => (
            <Col xs={12} sm={6} md={4} className="" key={marketResearch?.id}>
              <MarketResearchCard
                marketResearch={marketResearch}
                setUpload={setUpload}
                setEditMarketResearchData={setEditMarketResearchData}
                fetchMArketResearch={fetchMArketResearch}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default MarketResearch;
