import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack } from 'react-bootstrap';

const ProductGallery = () => {
  const navigate = useNavigate();
  return (
    <>
      <Stack direction="horizontal" gap={2} className="my-2">
        <Button
          variant="primary"
          style={{ marginLeft: 'auto', marginRight: 10 }}
          onClick={() => navigate('/dashboard/add-product')}>
          Add Product
        </Button>
      </Stack>
    </>
  );
};

export default ProductGallery;
