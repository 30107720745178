import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';

const ProspectForm = ({ hasLabel }) => {
  // State
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    preferred_contact: '',
    isAccepted: false
  });

  // Function to handle form field changes
  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  //function to validate the data entered into the form is acceptable and can be posted to the database
  const validateFormData = data => {
    // Check that the first name and last name are not empty
    if (!data.first_name.trim() || !data.last_name.trim()) {
      toast.error('First name and last name are required');
      return false;
    }

    // Check that the email address is in a valid format
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(data.email)) {
      toast.error('Invalid email address');
      return false;
    }

    // Check that the phone number only contains digits
    const phoneRegex = /^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/;
    if (!phoneRegex.test(data.phone_number)) {
      toast.error(`
      Invalid phone number, allowed formats are: 9999999999, 999-999-9999, (999) 999-9999`);
      return false;
    }

    // Check that the preferred contact method is either "email" or "text"
    if (
      data.preferred_contact !== 'email' &&
      data.preferred_contact !== 'text'
    ) {
      toast.error(`
      Invalid preferred contact method`);
      return false;
    }

    // Check that the terms and privacy policy have been accepted
    if (!data.isAccepted) {
      toast.error('You must accept the terms and privacy policy');
      return false;
    }

    // If all checks pass, return true
    return true;
  };

  // Handler (what happens when submit is pressed)
  const handleSubmit = async e => {
    try {
      e.preventDefault();

      // Validate the form data
      if (!validateFormData(formData)) {
        return;
      }
      const res = await fetch(
        // "https://fantastic-train-grqvg6q9p4pf95xp-7071.app.github.dev" + '/api/DemoRequest',
        process.env.REACT_APP_AZURE_FUNCTIONS_BASE_URL + '/api/DemoRequest',
        {
          method: 'POST',
          // headers: {
          //   'Content-Type': 'application/json',
          //   'x-functions-key': '2iX4NSpjqJ0Ot-caCdSDewjx_JWrteA4tih15O8nJcYoAzFuXmaA8g==',
          // },
          body: JSON.stringify(formData)
        }
      );
      const response = await res.json();
      console.log({ response });

      if (!res.ok) {
        // If the status code is not 200, throw an error
        throw new Error(
          `An error occurred while processing your request. Please try again later.`
        );
      }
      toast.success(`Successfully registered as ${formData.first_name}`, {
        theme: 'colored'
      });
    } catch (error) {
      console.error('Error:', error.message);
      toast.error(`
      An error occurred while processing your request. Please try again later.`);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>First Name</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'First Name' : ''}
          value={formData.first_name}
          name="first_name"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Last Name</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Last Name' : ''}
          value={formData.last_name}
          name="last_name"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Phone Number</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Phone number' : ''}
          value={formData.phone_number}
          name="phone_number"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Preferred Contact Method</Form.Label>}
        <Form.Select
          value={formData.preferred_contact}
          name="preferred_contact"
          onChange={handleFieldChange}>
          <option value="">Select...</option>
          <option value="email">Email</option>
          <option value="text">Text Message</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Check type="checkbox" id="acceptCheckbox" className="form-check">
          <Form.Check.Input
            type="checkbox"
            name="isAccepted"
            checked={formData.isAccepted}
            onChange={e =>
              setFormData({
                ...formData,
                isAccepted: e.target.checked
              })
            }
          />
          <Form.Check.Label className="form-label">
            I accept the <Link to="#!">terms</Link> and{' '}
            <Link to="#!">privacy policy</Link>
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>
      <Form.Group className="mb-4">
        <Button
          className="w-100"
          type="submit"
          disabled={
            !formData.first_name ||
            !formData.last_name ||
            !formData.email ||
            !formData.phone_number ||
            !formData.preferred_contact ||
            !formData.isAccepted
          }>
          Request Information
        </Button>
      </Form.Group>
    </Form>
  );
};

ProspectForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default ProspectForm;
