import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
// import { useSetRecoilState } from 'recoil';
// import { userState } from 'recoil/atoms/auth';

const LoginForm = ({ hasLabel, layout }) => {
  const navigate = useNavigate();
  // const setUser = useSetRecoilState(userState);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm();
  // Handler
  const onSubmit = async data => {
    try {
      const payload = {
        user: {
          email: data.email.toLowerCase().trim(),
          password: data.password
        },
        authentication: 'login'
      };

      const userResp = await fetch(
        process.env.REACT_APP_AZURE_FUNCTIONS_BASE_URL +
          '/api/authenticateIntoApp',
        {
          method: 'POST',
          body: JSON.stringify(payload)
        }
      );

      const userData = await userResp.json();
      console.log(userData);
      if (userData.statusCode === 200) {
        // setUser(userData.User);
        // localStorage.setItem('accessToken', userData.token || '');
        return navigate('/two-factor-auth', { state: userData.User });
        // return toast.success(`Logged in as ${data.email}`, {
        //   theme: 'colored'
        // });
      }
      throw new Error(userData.message);
    } catch (err) {
      console.log({ err });
      toast.error(`ERROR: ${err.message}`, {
        theme: 'colored'
      });
    }
  };
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3">
          {hasLabel && <Form.Label>Email address</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Email address' : ''}
            {...register('email', { required: 'This is required!' })}
          />
          {errors.email && (
            <Form.Label className="text-danger pt-2 m-0">
              {errors.email.message}
            </Form.Label>
          )}
        </Form.Group>

        <Form.Group className="mb-3">
          {hasLabel && <Form.Label>Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Password' : ''}
            {...register('password', { required: 'This is required!' })}
            type="password"
          />
          {errors.password && (
            <Form.Label className="text-danger pt-2 m-0">
              {errors.password.message}
            </Form.Label>
          )}
        </Form.Group>

        <Row className="justify-content-between align-items-center">
          <Col xs="auto">
            <Form.Check type="checkbox" id="rememberMe" className="mb-0">
              <Form.Check.Input type="checkbox" {...register('remember')} />
              <Form.Check.Label className="mb-0 text-700">
                Remember me
              </Form.Check.Label>
            </Form.Check>
          </Col>

          <Col xs="auto">
            <Link
              className="fs--1 mb-0"
              to={`/authentication/${layout}/forgot-password`}>
              Forgot Password?
            </Link>
          </Col>
        </Row>

        <Form.Group>
          <Button
            type="submit"
            color="primary"
            className="mt-3 w-100"
            disabled={isSubmitting}>
            Log in
          </Button>
        </Form.Group>

        {/* <Divider className="mt-4">or log in with</Divider>

      <SocialAuthButtons /> */}
      </Form>
    </>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
