/* eslint-disable prettier/prettier */
import React, { useMemo, useRef, useState } from 'react';
import { Button, Card, Overlay, Popover, Stack } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { MdOutlineMoreVert } from "react-icons/md";
import { HEADERS } from 'helpers/utils';
import { useRecoilValue } from 'recoil';
import { userState } from 'recoil/atoms/auth';
import './style.css'


const MarketResearchCard = ({ marketResearch, setEditMarketResearchData, setUpload, fetchMArketResearch }) => {

    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const ref = useRef(null);

    const user = useRecoilValue(userState)



    // const popover = (
    //     <Popover id="popover-basic">
    //         <Popover.Header as="h3">Popover right</Popover.Header>
    //         <Popover.Body>
    //             And here's some <strong>amazing</strong> content. It's very engaging.
    //             right?
    //         </Popover.Body>
    //     </Popover>
    // );

    const handleClick = (event) => {
        setTarget(event.target);
        setShow(!show);
    };

    const handleEdit = () => {
        setEditMarketResearchData(marketResearch);
        setUpload(true);
        setShow(false)
        console.log({ user })
    }

    const handleDelete = async () => {
        try {
            setIsDeleting(true)
            const response = await fetch(
                process.env.REACT_APP_AZURE_FUNCTIONS_BASE_URL + '/api/marketResearch', {
                method: 'POST',
                body: JSON.stringify({
                    id: marketResearch.id,
                    apiType: 'deleteMarketResearch',
                    userId: user?.id
                }),
                headers: HEADERS
            });
            const data = await response.json();
            if (data?.status === 'success') {
                await fetchMArketResearch()
            }
            setShow(false)
            console.log(data)
        } catch (error) {
            console.log(error);
        }
        finally {
            setIsDeleting(false)
        }
    }

    const isOwner = useMemo(() => marketResearch?.createdBy === user?.id, [marketResearch])

    return (
        <Card style={{ width: '100%', height: '100%' }}>
            <Card.Body style={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
                <Stack
                    direction='horizontal'
                    style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}
                    gap={2}
                >
                    <Card.Title style={{ textTransform: 'capitalize' }}>
                        {marketResearch?.contentType}
                    </Card.Title>
                    <Overlay
                        show={show}
                        target={target}
                        placement="bottom-end"
                        container={ref}
                        containerPadding={20}
                        rootClose={true}
                        onHide={() => setShow(false)}
                    >
                        <Popover id="popover-contained">
                            <Popover.Header as="h3">Actions</Popover.Header>
                            <Popover.Body as={Stack} direction='row' gap={2}>
                                <Button variant="primary" onClick={handleEdit} disabled={!isOwner}>Edit</Button>
                                <Button variant="danger" onClick={handleDelete} disabled={isDeleting || !isOwner}>
                                    {isDeleting ? 'Deleting...' : 'Delete'}
                                </Button>
                            </Popover.Body>
                        </Popover>
                    </Overlay>
                    <div ref={ref} onClick={handleClick} style={{ cursor: 'pointer' }}>
                        {isOwner && <MdOutlineMoreVert size={22} />}
                    </div>
                </Stack>
                <Card.Text style={{ color: 'black', marginBottom: '4px' }}>{marketResearch?.publisher}</Card.Text>
                <Card.Text style={{ color: 'GrayText', marginBottom: '6px' }}>{marketResearch?.publication}</Card.Text>
                <Card.Text style={{ height: '100%', marginBottom: '10px' }} className="truncate-multi-line">
                    {marketResearch?.summary}
                </Card.Text>
                <Button
                    variant="primary"
                    style={{ marginTop: 'auto', width: 'auto' }}
                    onClick={() => {
                        window.open(marketResearch?.fileUrl, '_blank');
                    }}>
                    Check out
                </Button>
            </Card.Body>
        </Card>
    );
};

MarketResearchCard.propTypes = {
    marketResearch: PropTypes.object,
    setEditMarketResearchData: PropTypes.func,
    setUpload: PropTypes.func,
    fetchMArketResearch: PropTypes.func
};

export default MarketResearchCard;
