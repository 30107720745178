import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { userState } from 'recoil/atoms/auth';
import { useSetRecoilState } from 'recoil';
import { toast } from 'react-toastify';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

function TwoFactorAuth() {
  const setUser = useSetRecoilState(userState);
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();

  if (!state) {
    return <Navigate to="/" />;
  }

  const handleResend = async () => {
    try {
      setIsDisabled(true);
      const payload = {
        user: state,
        authentication: 'sendOtp'
      };
      const resp = await fetch(
        process.env.REACT_APP_AZURE_FUNCTIONS_BASE_URL +
          '/api/authenticateIntoApp',
        {
          method: 'POST',
          body: JSON.stringify(payload)
        }
      );
      const response = await resp.json();
      // Set a timer to re-enable the button after 5 minutes
      response.statusCode === 200 &&
        toast.success(response.message, {
          theme: 'colored'
        });
      response.message;
      setTimeout(() => {
        setIsDisabled(false);
      }, 300000); // 300,000 milliseconds = 5 minutes
    } catch (err) {
      console.log(err.message);
    }
  };

  const verifyCode = async () => {
    try {
      setIsSubmitting(true);
      const response = await fetch(
        process.env.REACT_APP_AZURE_FUNCTIONS_BASE_URL + '/api/verify2fa',
        {
          method: 'POST',
          body: JSON.stringify({ userId: state.id, otpToVerify: code })
        }
      );
      const res = await response.json();
      if (res.token) {
        localStorage.setItem('accessToken', res.token);
        setUser(res.User);
        navigate('/dashboard');
        toast.success('Logged in successfully', {
          theme: 'colored'
        });
      } else {
        setError('Invalid code. Please try again.');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      style={{
        height: '99vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <div
        style={{
          backgroundColor: 'white',
          padding: '20px 30px',
          borderRadius: 10,
          textAlign: 'center'
        }}>
        <h3>Two Factor Authentication</h3>
        <p>Check you email.</p>
        <Form.Group>
          <Form.Control
            type="text"
            value={code}
            onChange={e => setCode(e.target.value)}
            placeholder="Enter your 2FA code"
          />
        </Form.Group>
        <Form.Group>
          <Button
            type="button"
            color="primary"
            className="mt-3 w-100"
            disabled={isSubmitting || !code}
            onClick={verifyCode}>
            Verify
          </Button>
        </Form.Group>
        {error && (
          <p
            style={{
              marginTop: 10
            }}>
            {error}.{' '}
            {!isDisabled && (
              <button
                type="button"
                disabled={isDisabled}
                style={{
                  border: 0,
                  background: 'white'
                }}
                onClick={handleResend}>
                Resend code
              </button>
            )}
          </p>
        )}
      </div>
    </div>
  );
}

export default TwoFactorAuth;
