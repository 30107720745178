import React, { useState } from 'react';
import {
  Button,
  Col,
  Container,
  InputGroup,
  Nav,
  Row,
  Tab,
  FormControl
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CiHeart } from 'react-icons/ci';
import { FaCartArrowDown } from 'react-icons/fa';

const ProductDetails = () => {
  const [count, setCount] = useState(0); // State to manage the count value

  // Function to increment the count
  const handleIncrement = () => {
    setCount(count + 1);
  };

  // Function to decrement the count
  const handleDecrement = () => {
    if (count > 0) setCount(count - 1); // Ensure count doesn't go below 0
  };

  // Function to handle direct input changes
  const handleInputChange = e => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value >= 0) setCount(value); // Only allow valid numbers >= 0
  };

  const [selectedImage, setSelectedImage] = useState(
    'https://images.unsplash.com/photo-1481487196290-c152efe083f5?q=80&w=1562&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
  ); // Default main image
  const tags = ['Computer', 'MacBook', 'MacBook Pro', 'Laptop']; // Tags array
  const thumbnailImages = [
    'https://images.unsplash.com/photo-1481487196290-c152efe083f5?q=80&w=1562&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://images.unsplash.com/photo-1484788984921-03950022c9ef?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE0fHx8ZW58MHx8fHx8',
    'https://images.unsplash.com/1/work-station-straight-on-view.jpg?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDd8fHxlbnwwfHx8fHw%3D',
    'https://images.unsplash.com/1/work-station-straight-on-view.jpg?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDd8fHxlbnwwfHx8fHw%3D'
  ];
  return (
    <div style={{ padding: '10px 20px', backgroundColor: 'white' }}>
      <Container className="mt-5">
        <Row>
          {/* Product Images Section */}
          <Col md={5}>
            <div className="product-image mb-4">
              <img
                src={selectedImage}
                alt="Product"
                className="img-fluid rounded"
              />
            </div>
            <Row>
              {thumbnailImages.map((src, index) => (
                <Col
                  xs={3}
                  key={index}
                  className="thumbnail-image"
                  style={{ padding: '0 5px' }}>
                  <img
                    src={src}
                    alt="Thumbnail"
                    className={`img-fluid  mb-2 rounded ${
                      selectedImage === src ? 'border border-primary' : ''
                    }`} // Highlight selected thumbnail
                    style={{
                      cursor: 'pointer',
                      width: '100px',
                      height: '80px',
                      objectFit: 'cover'
                    }}
                    onClick={() => setSelectedImage(src)} // Change the main image on click
                  />
                </Col>
              ))}
            </Row>
          </Col>

          {/* Product Details Section */}
          <Col md={7}>
            <h4 style={{ fontSize: '19px', marginBottom: 0 }}>Product Name</h4>
            <Link to="#" style={{ fontSize: '13px' }}>
              Product Web Link
            </Link>
            <p style={{ fontSize: '13px', marginBottom: 0 }}>
              Product Description
            </p>
            <p style={{ fontSize: '13px' }}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
              aliquam molestias vel veroLorem ipsum dolor sit amet, consectetur
              adipisicing elit. Eos aliquam molestias vel vero dolor sit amet,
              consectetur adipisicing elit. Eos aliquam molestias vel veroLore
              dolor sit amet, consectetur adipisicing elit. Eos aliquam
              molestias vel veroLore dolor sit amet, consectetur adipisicing
              elit. Eos aliquam molestias vel veroLore dolor sit amet,
              consectetur adipisicing elit. Eos aliquam molestias vel veroLore
            </p>

            <p
              className="text-danger "
              style={{ fontSize: '23px', marginBottom: 0 }}>
              $1200{' '}
              <span
                className="text-muted text-decoration-line-through"
                style={{ fontSize: '13px' }}>
                $2400
              </span>{' '}
              <span
                className="text-muted font-weight-bold"
                style={{ fontSize: '13px' }}>
                - 50%{' '}
              </span>
            </p>
            <p style={{ fontSize: '13px', marginBottom: 0 }}>
              Shipping Cost: $50
            </p>
            <p style={{ fontSize: '13px' }}>
              Stock: <span className="text-success">Available</span>
            </p>
            <p style={{ fontSize: '13px' }}>
              Tags:{' '}
              {tags.map((tag, index) => (
                <span key={index} className="text-primary cursor-pointer">
                  {tag}
                  {index < tags.length - 1 && ', '}
                </span>
              ))}
            </p>

            <div style={{ display: 'flex', gap: '10px' }}>
              <InputGroup className="w-auto align-items-center">
                <Button
                  style={{
                    border: '1px solid #ccc',
                    backgroundColor: 'white',
                    color: 'black'
                  }}
                  onClick={handleDecrement}>
                  -
                </Button>
                <FormControl
                  type="number"
                  disabled
                  value={count}
                  onChange={handleInputChange}
                  className="text-center"
                  style={{
                    maxWidth: '60px',
                    backgroundColor: 'white',
                    border: '1px solid #ccc'
                  }}
                  min="0"
                />
                <Button
                  style={{
                    border: '1px solid #ccc',
                    backgroundColor: 'white',
                    color: 'black'
                  }}
                  onClick={handleIncrement}>
                  +
                </Button>
              </InputGroup>
              <Button variant="primary">
                <FaCartArrowDown style={{ marginBottom: '4px' }} />{' '}
                <span>Add to Cart</span>
              </Button>

              <Button
                style={{ border: '1px solid gray', backgroundColor: 'white' }}>
                <CiHeart
                  size={20}
                  color="red"
                  style={{ marginBottom: '4px' }}
                />{' '}
                <span style={{ color: 'red' }}>282</span>
              </Button>
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: '20px' }}>
          <Col xs={12}>
            <Tab.Container defaultActiveKey="description" className="mt-5">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="description">Description</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="specifications">Specifications</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="reviews">Reviews</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className="mt-4">
                <Tab.Pane eventKey="description" style={{ fontSize: '16px' }}>
                  <p>
                    Over the years, Apple has built a reputation for releasing
                    its products with a lot of fanfare – but that didn’t exactly
                    happen for the MacBook Pro 2018. Rather, Apple’s latest pro
                    laptop experienced a subdued launch, in spite of it offering
                    a notable spec upgrade over the 2017 model – along with an
                    improved keyboard. And, as with previous generations the
                    15-inch MacBook Pro arrives alongside a 13-inch model.
                  </p>
                  <p>
                    Apple still loves the MacBook Pro though, despite the quiet
                    release. This is because, while the iPhone XS and iPad,
                    along with the 12-inch MacBook, are aimed at everyday
                    consumers, the MacBook Pro has always aimed at the creative
                    and professional audience. This new MacBook Pro brings a
                    level of performance (and price) unlike its more
                    consumer-oriented devices.
                  </p>
                  <p>
                    Still, Apple wants mainstream users to buy the MacBook Pro,
                    too. So, if you’re just looking for the most powerful
                    MacBook on the market, you’ll love this new MacBook Pro.
                    Just keep in mind that, while the keyboard has been updated,
                    there are still some issues with it.
                  </p>
                  <p>
                    There’s enough of a difference between the two sizes when it
                    comes to performance to warrant two separate reviews, and
                    here we’ll be looking at how the flagship 15-inch MacBook
                    Pro performs in 2019.
                  </p>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="specifications"
                  style={{ fontSize: '16px' }}>
                  <p>
                    Over the years, Apple has built a reputation for releasing
                    its products with a lot of fanfare – but that didn’t exactly
                    happen for the MacBook Pro 2018. Rather, Apple’s latest pro
                    laptop experienced a subdued launch, in spite of it offering
                    a notable spec upgrade over the 2017 model – along with an
                    improved keyboard. And, as with previous generations the
                    15-inch MacBook Pro arrives alongside a 13-inch model.
                  </p>
                  <p>
                    Apple still loves the MacBook Pro though, despite the quiet
                    release. This is because, while the iPhone XS and iPad,
                    along with the 12-inch MacBook, are aimed at everyday
                    consumers, the MacBook Pro has always aimed at the creative
                    and professional audience. This new MacBook Pro brings a
                    level of performance (and price) unlike its more
                    consumer-oriented devices.
                  </p>
                  <p>
                    Still, Apple wants mainstream users to buy the MacBook Pro,
                    too. So, if you’re just looking for the most powerful
                    MacBook on the market, you’ll love this new MacBook Pro.
                    Just keep in mind that, while the keyboard has been updated,
                    there are still some issues with it.
                  </p>
                  <p>
                    There’s enough of a difference between the two sizes when it
                    comes to performance to warrant two separate reviews, and
                    here we’ll be looking at how the flagship 15-inch MacBook
                    Pro performs in 2019.
                  </p>
                </Tab.Pane>
                <Tab.Pane eventKey="reviews" style={{ fontSize: '16px' }}>
                  <p>
                    Over the years, Apple has built a reputation for releasing
                    its products with a lot of fanfare – but that didn’t exactly
                    happen for the MacBook Pro 2018. Rather, Apple’s latest pro
                    laptop experienced a subdued launch, in spite of it offering
                    a notable spec upgrade over the 2017 model – along with an
                    improved keyboard. And, as with previous generations the
                    15-inch MacBook Pro arrives alongside a 13-inch model.
                  </p>
                  <p>
                    Apple still loves the MacBook Pro though, despite the quiet
                    release. This is because, while the iPhone XS and iPad,
                    along with the 12-inch MacBook, are aimed at everyday
                    consumers, the MacBook Pro has always aimed at the creative
                    and professional audience. This new MacBook Pro brings a
                    level of performance (and price) unlike its more
                    consumer-oriented devices.
                  </p>
                  <p>
                    Still, Apple wants mainstream users to buy the MacBook Pro,
                    too. So, if you’re just looking for the most powerful
                    MacBook on the market, you’ll love this new MacBook Pro.
                    Just keep in mind that, while the keyboard has been updated,
                    there are still some issues with it.
                  </p>
                  <p>
                    There’s enough of a difference between the two sizes when it
                    comes to performance to warrant two separate reviews, and
                    here we’ll be looking at how the flagship 15-inch MacBook
                    Pro performs in 2019.
                  </p>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProductDetails;
