import React from 'react';
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { ListGroup, Stack } from 'react-bootstrap';
import { sidebarConfig, colorsObj } from 'helpers/utils';
import { RxHamburgerMenu } from 'react-icons/rx';
import { useNavigate } from 'react-router-dom';

function AmanitaMobileNav() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(null);
  const [isDark] = useState(false);

  const handleNavigate = (item, index) => {
    setActiveItem(index);
    navigate(
      // sidebarConfig[index.split('-')[0]].items[index.split('-')[1]].path
      item.path
    );
    handleClose();
    console.log(index);
  };
  return (
    <div>
      <button
        // style={{ backgroundColor: isDark ? colorsObjDark.sidebg : 'white' }}
        onClick={handleShow}
        className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center d-block d-lg-none">
        <RxHamburgerMenu color="white" size={28} />
      </button>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <h1
            style={{
              fontSize: '1rem',
              textAlign: 'center',
              padding: '0.8rem 0'
            }}>
            Company Name
          </h1>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Stack className="">
            {sidebarConfig.map((v, subIndex) => (
              <ListGroup
                defaultActiveKey="#link1"
                key={subIndex}
                className="pb-3"
                style={{ paddingLeft: 10 }}>
                <p
                  className="m-0 mb-2"
                  style={{ color: isDark ? 'white' : 'black' }}>
                  {v.subheader}
                </p>
                {v.items.map((item, itemIndex) => {
                  const index = `${subIndex}-${itemIndex}`;
                  return (
                    <ListGroup.Item
                      onClick={() => handleNavigate(item, index)}
                      action
                      key={itemIndex}
                      className="m-0 py-2 px-3 cursor-pointer mt-2"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 10,
                        backgroundColor:
                          activeItem === index
                            ? isDark
                              ? '#C265F2'
                              : '#F6EFDB'
                            : isDark
                            ? '#762615'
                            : colorsObj.halfWhite,
                        borderRadius: 10,
                        border: 0,
                        color: isDark ? 'white' : 'black'
                      }}>
                      {item.icon(isDark)}
                      {item.title}
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            ))}
          </Stack>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default AmanitaMobileNav;
