import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { userState } from 'recoil/atoms/auth';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

const RegistrationForm = ({ hasLabel }) => {
  const [user] = useRecoilState(userState);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { isSubmitting, errors }
  } = useForm();
  // Handler
  const onSubmit = async data => {
    try {
      const payload = {
        user: {
          name: data.name,
          email: data.email.toLowerCase().trim(),
          password: data.password
        },
        authentication: 'register'
      };

      const userResp = await fetch(
        process.env.REACT_APP_AZURE_FUNCTIONS_BASE_URL +
          '/api/authenticateIntoApp',
        {
          method: 'POST',
          body: JSON.stringify(payload)
        }
      );

      const userData = await userResp.json();
      console.log(userData);
      if (userData.statusCode === 200) {
        // setUser(userData.User);
        // return toast.success(`Logged in as ${data.email}`, {
        //   theme: 'colored'
        // });
        return navigate('/two-factor-auth', { state: userData.User });
      }
      throw new Error(userData.message);
    } catch (err) {
      toast.error(`ERROR: ${err.message}`, {
        theme: 'colored'
      });
    }
  };
  console.log(user);
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Name</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Name' : ''}
          {...register('name', { required: 'This is required!' })}
        />
        {errors.name && (
          <Form.Label className="text-danger pt-2 m-0">
            {errors.name.message}
          </Form.Label>
        )}
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          {...register('email', {
            required: 'This is required!',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'invalid email address'
            }
          })}
        />
        {errors.email && (
          <Form.Label className="text-danger pt-2 m-0">
            {errors.email.message}
          </Form.Label>
        )}
      </Form.Group>

      <Row className="g-2 mb-3">
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Password' : ''}
            {...register('password', { required: 'This is required!' })}
            type="password"
          />
          {errors.password && (
            <Form.Label className="text-danger pt-2 m-0">
              {errors.password.message}
            </Form.Label>
          )}
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Confirm Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Confirm Password' : ''}
            {...register('confirmPassword', {
              required: 'This is required!',
              validate: {
                matchesPreviousPassword: value => {
                  const { password } = getValues();
                  return password === value || 'Passwords should match!';
                }
              }
            })}
            type="password"
          />
          {errors.confirmPassword && (
            <Form.Label className="text-danger pt-2 m-0">
              {errors.confirmPassword.message}
            </Form.Label>
          )}
        </Form.Group>
      </Row>

      {/* <Form.Group className="mb-3">
        <Form.Check type="checkbox" id="acceptCheckbox" className="form-check">
          <Form.Check.Input
            type="checkbox"
             {...register('isAccepted', { required: 'This is required!' })}
          />
          <Form.Check.Label className="form-label">
            I accept the <Link to="#!">terms</Link> and{' '}
            <Link to="#!">privacy policy</Link>
          </Form.Check.Label>
        </Form.Check>
      </Form.Group> */}

      <Form.Group className="mb-4">
        <Button className="w-100" type="submit" disabled={isSubmitting}>
          Register
        </Button>
      </Form.Group>
      {/* <Divider>or register with</Divider>

      <SocialAuthButtons /> */}
    </Form>
  );
};

RegistrationForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default RegistrationForm;
