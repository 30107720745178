import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { userState } from 'recoil/atoms/auth';
import React from 'react';
import PropTypes from 'prop-types';

export default function AuthGuard({ children }) {
  const user = useRecoilValue(userState);
  const resetUser = useResetRecoilState(userState);
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      resetUser();
    }
  }, []);

  if (!user) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to="/" />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}

AuthGuard.propTypes = {
  children: PropTypes.node
};
