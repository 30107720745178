/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import '../Pages/market-research/style.css';
import { useForm } from 'react-hook-form';
import { colorsObjDark, HEADERS } from 'helpers/utils';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { userState } from 'recoil/atoms/auth';
import { useRecoilValue } from 'recoil';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';


const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const Upload = ({ upload, setUpload, filter, onClose, editMarketResearchData, fetchMArketResearch }) => {

  // const modalRef = useRef(null);
  const isDark = false;
  const user = useRecoilValue(userState)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const initialValues = {
    id: '',
    file: null,
    publisher: '',
    publication: '',
    summary: '',
    fileName: '',
    contentType: ''
  }

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    clearErrors,
    formState: { errors }
  } = useForm({
    defaultValues: {
      ...initialValues
    }
  })

  const onSubmit = async data => {
    console.log(data);
    try {
      setIsSubmitting(true)
      let file;
      if (!editMarketResearchData) {
        file = await toBase64(data.file);
      }
      const payload = {
        publisher: data.publisher,
        publication: data.publication,
        contentType: data.contentType,
        summary: data.summary,
        userId: user?.id,
        // file: (editMarketResearchData && getValues('id')) ? editMarketResearchData?.fileName : file,
        apiType: (editMarketResearchData && getValues('id')) ? 'updateMarketResearch' : 'postMarketResearch',
        ...(editMarketResearchData && {
          id: data?.id,
          fileUrl: editMarketResearchData?.fileUrl
        }),
        ...(!editMarketResearchData && {
          file: file
        })
      };
      console.log({ data, payload });

      const marketResearch = await fetch(
        process.env.REACT_APP_AZURE_FUNCTIONS_BASE_URL + '/api/marketResearch',
        {
          method: 'POST',
          body: JSON.stringify(payload),
          headers: HEADERS
        }
      );

      const newData = await marketResearch.json()
      console.log({ newData });
      if (newData?.status === 'success') {
        toast.success(newData?.message, {
          position: 'top-right',
          autoClose: 5000,
        });
        onClose();
        reset(initialValues);
        await fetchMArketResearch();
      }
    } catch (error) {
      console.log(error);
      toast.success(error?.message || 'Error', {
        position: 'top-right',
        autoClose: 4000,
      });
    }
    finally {
      setIsSubmitting(false)
    }
  };

  const handleClick = () => {
    setUpload(!upload);
  };

  const handleButtonClick = () => {
    document.getElementById('fileInput').click();
    // fileInputRef?.current?.click(); // Use the ref to trigger the file input click
  };

  const handleFileChange = (event) => {
    console.log(event.target.files);
    const file = event.target.files[0];
    if (file) {
      setValue('fileName', file.name);
      setValue('file', file);
      clearErrors('file')
      clearErrors('fileName')
    } else {
      setValue('fileName', '');
      setValue('file', null);
    }
  };

  const handleClose = () => {
    setUpload(false);
    onClose();
    editMarketResearchData && reset(initialValues);
    reset();
    clearErrors();
  };


  useEffect(() => {
    if (editMarketResearchData) {
      setValue('id', editMarketResearchData.id);
      setValue('publisher', editMarketResearchData.publisher);
      setValue('publication', editMarketResearchData.publication);
      setValue('summary', editMarketResearchData.summary);
      setValue('fileName', editMarketResearchData.fileUrl);
      setValue('contentType', editMarketResearchData.contentType);
    }
    return () => {
      // reset({
      //   id: '',
      //   file: '',
      //   publisher: '',
      //   publication: '',
      //   summary: '',
      //   fileName: '',
      //   contentType: ''
      // });
    }
  }, [editMarketResearchData]);

  // console.log({errors, values: getValues()});

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setFileName(file.name);
  //     setValue(name, file);
  //   }
  // };


  return (
    <div className="upload">
      <button
        style={
          filter
            ? {
              backgroundColor: isDark ? colorsObjDark.pink : '#F6EFDB',
              color: isDark ? 'white' : 'black'
            }
            : { backgroundColor: colorsObjDark.sidebg }
        }
        onClick={handleClick}
        className="main-btn">
        Upload
      </button>
      <div>
        <Modal show={upload} onHide={handleClose}>
          <form className='upload_modal_form' onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
              <Modal.Title onClick={() => {
                console.log({ errors, values: getValues(), editMarketResearchData });
              }}>Upload Market Research</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col sm={12}>
                  <Form.Group
                    className="form_group mb-3"
                  // controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Publisher</Form.Label>
                    <Form.Control
                      {...register('publisher', { required: true })}
                    />
                    {errors.publisher && <span>This field is required</span>}
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group
                    className="form_group mb-3"
                  // controlId="exampleForm.ControlInput2"
                  >
                    <Form.Label>Publication Date</Form.Label>
                    <Form.Control
                      type='date'
                      {...register('publication', { required: true })}
                    />
                    {errors.publication && <span>This field is required</span>}
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group
                    className="form_group mb-3"
                  // controlId="exampleForm.ControlInput3"
                  >
                    <Form.Label>Content Type</Form.Label>
                    <Form.Control
                      {...register('contentType', { required: true })}
                    />
                    {errors.contentType && <span>This field is required</span>}
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group
                    className="form_group mb-3"
                  // controlId="exampleForm.ControlInput3"
                  >
                    <Form.Label>Summary</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={5}
                      style={{ resize: 'none' }}
                      {...register('summary', { required: true })}
                    />
                    {errors.summary && <span>This field is required</span>}
                  </Form.Group>
                </Col>
                {!editMarketResearchData && (
                  <Col xs={12}>
                    <Form.Group className="form_group mb-3">
                      <Form.Label>File Upload</Form.Label>
                      <InputGroup>
                        <Button
                          variant="primary"
                          onClick={() => handleButtonClick()}
                          disabled={editMarketResearchData}
                        >
                          Choose File
                        </Button>
                        <Form.Control
                          type="text"
                          placeholder="No file chosen"
                          {...register('fileName', {
                            required: true,
                            // eslint-disable-next-line no-extra-boolean-cast
                            // value: Boolean(editMarketResearchData) ? (getValues('fileName')?.slice(0, 15) + "...") : getValues('fileName')
                          })}
                          value={editMarketResearchData ? 'File Exists' : watch('fileName')}
                          readOnly
                        // disabled
                        />
                        <Form.Control
                          type="file"
                          id='fileInput'
                          {...register('file', { required: false })}
                          hidden
                          accept=".pdf,.doc,.docx,.xls,.xlsx,image/*"
                          onChange={handleFileChange}
                        // disabled={true}
                        />
                      </InputGroup>
                      {errors.fileName && <span>File is required</span>}
                    </Form.Group>
                  </Col>
                )}

              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type='submit' disabled={isSubmitting}>
                {isSubmitting ? 'Submitting...' :
                  editMarketResearchData ? 'Update Market Research' : 'Add to library'
                }
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>

    </div>
  );
};

Upload.propTypes = {
  upload: PropTypes.bool,
  setUpload: PropTypes.func,
  filter: PropTypes.bool,
  editMarketResearchData: PropTypes.object || null,
  onClose: PropTypes.func,
  fetchMArketResearch: PropTypes.func
};

export default Upload;
