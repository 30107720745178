import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userState } from 'recoil/atoms/auth';
import React from 'react';
import PropTypes from 'prop-types';

export default function GuestGuard({ children }) {
  const user = useRecoilValue(userState);

  if (user) {
    return <Navigate to={'/dashboard'} />;
  }

  return <>{children}</>;
}

GuestGuard.propTypes = {
  children: PropTypes.node
};
