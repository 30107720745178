{
  /* CHANGE LOG
    1. Changed text to reflect Amanita
    2. Moved teaser image up to align with text */
}

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppContext } from 'Main';
import bg1 from 'assets/img/generic/Amanita.png';
import dashboardDark from 'assets/img/generic/dashboard-alt-dark.png';
import dashboard from 'assets/img/generic/dashboard-alt.jpg';
import Section from 'components/common/Section';
import ProspectForm from 'components/authentication/ProspectForm';
import { Modal } from 'react-bootstrap';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Typewriter } from 'react-simple-typewriter';

const Hero = () => {
  const {
    config: { isDark, isRTL }
  } = useAppContext();

  // Sets the state of the prospect form to hide
  const [showModal, setShowModal] = useState(false);

  return (
    <Section
      className="py-0 overflow-hidden"
      data-bs-theme="light"
      image={bg1}
      position="center bottom"
      overlay>
      <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
        <Col
          md={11}
          lg={7}
          xl={5}
          className="pb-7 pb-xl-9 text-center text-xl-start">
          <Button
            as={Link}
            variant="danger"
            className="mb-4 fs--1 border-2 rounded-pill"
            onClick={() => setShowModal(true)}>
            <span className="me-2" role="img" aria-label="Mushroom">
              🍄
            </span>
            Level up with Amanita
          </Button>

          <h1 className="text-white fw-bold">
            Grow{' '}
            <span className="fw-bold">
              <Typewriter
                words={['awareness', 'engagement', 'sales', 'retention']}
                loop={false}
                cursor={!isRTL ? true : false}
                cursorStyle="|"
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span>
            <br />
            with the #1 Go-To-Market Enablement Platform.
          </h1>
          <p className="lead text-white fw-bold">
            Amanita is the leading growth platform for entrepreneurs and
            enterprise Go-To-Market leaders - automating launch planning,
            competitive intelligence, content personalization, and campaign
            planning.
          </p>
          <Button
            as={Link}
            variant="light"
            size="lg"
            className="border-2 rounded-pill mt-4 fs-0 py-2"
            onClick={() => setShowModal(true)}>
            Revenue growth starts here.
            <FontAwesomeIcon icon="play" transform="shrink-6 down-1 right-5" />
          </Button>

          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Prospect Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ProspectForm />
            </Modal.Body>
          </Modal>
        </Col>
        <Col xl={{ span: 6, offset: 0 }} className="align-self-center ">
          <Link to="/" className="img-landing-banner">
            <img
              className="img-fluid"
              src={isDark ? dashboardDark : dashboard}
              alt=""
            />
          </Link>
        </Col>
      </Row>
    </Section>
  );
};

export default Hero;
